export default {
	for_el_cascader(data){

		//格式化车型数据
		let truck_type=[];
		for(var item of data){
			
			//二级分类
			let truck_type_meal=JSON.parse(item.meal);
			let truck_type_children=[];
			for(var meal_item of truck_type_meal){
				truck_type_children.push({
					label:meal_item.meal_name,
					value:meal_item.meal_name,
				});
			}

			//一级分类
			truck_type.push({
				label:item.name,
				value:item.name,
				children:[...truck_type_children],
			});
		}

		return truck_type
	}
}