<template>
	<div class="page">
		<el-form  label-width="100px" :disabled="act=='info'">
			

			<div class="big_tit" style="margin:0">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-select class="el_inner_width" v-model="form.cargo_name" @change="cargo_name_change" allow-create filterable placeholder="请输入货物名称">
						<el-option
							v-for="(item,index) in used_cargo_names"
							:key="index"
							:label="item"
							:value="item"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物类型" style="width:588px">
					<el-select class="el_inner_width" v-model="form.cargo_type" clearable>
						<el-option v-for="(item,index) in cargo_type" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货重(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_weight" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_volume" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装/卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.case_city" placeholder="装货地省市区" :options="cities" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="装货地详情" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_addr" placeholder="请输入装货地详细地址" clearable>
						<el-button slot="append">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.aim_city" placeholder="卸货地省市区" :options="cities" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货地详情" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_addr" placeholder="请输入卸货地详细地址" clearable>
						<el-button slot="append">地图</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">运费相关 (说明: 如果不填运费则只能进行招投标, 如果填写运费可以直接抢单也可以指定也可以招投标)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费(元)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.freight_total" placeholder="请输入运费信息" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算方式" style="width:588px">
					<el-select class="el_inner_width" v-model="form.settlement_type" clearable>
						<el-option label="按吨结算" value="1"></el-option>
						<el-option label="按方结算" value="2"></el-option>
						<el-option label="按趟结算" value="3"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="是否回程" style="width:388px">
					<el-select class="el_inner_width" v-model="form.is_need_return" clearable>
						<el-option label="需要回程" value="1"></el-option>
						<el-option label="不需要回程" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:588px">
					<el-input class="el_inner_width" v-model="form.distance" type="number" placeholder="单位(公里), 非必填" clearable>
						<el-button slot="append">公里</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装卸货地日期</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货日期" style="width:488px">
					<el-date-picker
						v-model="form.case_date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货日期" style="width:488px">
					<el-date-picker
						v-model="form.aim_date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit">其他信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车型要求" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.need_truck_type" placeholder="非必填" :options="truck_type" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单备注" style="width:588px">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div v-if="act=='edit'" style="margin-top:20px;text-align:center">
			<el-button type="primary" @click="sub">提交</el-button>
		</div>
	</div>
</template>
<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		data() {
			return {

				//操作
				act:'',

				//表单
				form: {
					id:'',//货源id
					truck_tord_num:'',//货源编号
					group1_num:'',//组别编号
					out_trade_num:'',//上游单号
					cargo_name:'',//货物名称
					cargo_type:'',//货物性质
					case_city:[],//装货地省市区
					case_addr:'',//装货地详情
					case_date:null,//装货时间
					aim_city:[],//卸货地省市区
					aim_addr:'',//卸货地详情
					aim_date:null,//卸货时间
					freight_total:null,//运费
					settlement_type:'3',//结算方式(0:未知,1:按吨数,2:按方数,3:按趟)
					is_need_return:'2',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					cargo_weight:'',//货物重量
					cargo_volume:'',//货物体积
					need_truck_type:null,//租用货车类型
					mark:'',//备注
				},

				//城市联动数据
				cities:regionData,

				//货物类型
				cargo_type:[],

				//货车类型
				truck_type:[],

				//使用过的货物名称
				used_cargo_names:[]
			}
		},
		created() {

			//返回地址
			this.come_from=this.$route.query.come_from;

			//使用过的货物名称初始化
			this.set_used_cargo_name();

			//取出操作符
			let act=this.$route.query.act;
			if(['info','edit'].indexOf(act)!=-1){
				this.act=act;
			}else{
				this.$my.other.msg({
					type:'warning',
					str:'参数有误,请退回货源列表页重新进入'
				});
				return;
			}

			//取出货源信息
			this.get_cargo_info()
		},
		mounted(){
			
		},
		methods: {

			//提交
			sub(){
				
				let post_data={
					mod:'truck_tord_real_time',
					ctr:'cargo_edit_admin',
					cargo_id:this.cargo_id,
					set:{}
				};

				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else post_data.set.cargo_name=this.form.cargo_name.trim()

				if(!this.form.cargo_type.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物性质'
					});
					return;
				}else post_data.set.cargo_type=this.form.cargo_type.trim()

				if(this.$my.check.is_num(this.form.cargo_weight)&&this.form.cargo_weight>0){//货物重量
					post_data.set.cargo_weight=this.form.cargo_weight
				}

				if(this.$my.check.is_num(this.form.cargo_volume)&&this.form.cargo_volume>0){//货物体积
					post_data.set.cargo_volume=this.form.cargo_volume
				}

				let case_prov=CodeToText[this.form.case_city[0]];
				let case_city=CodeToText[this.form.case_city[1]];
				let case_county=CodeToText[this.form.case_city[2]];
				let aim_prov=CodeToText[this.form.aim_city[0]];
				let aim_city=CodeToText[this.form.aim_city[1]];
				let aim_county=CodeToText[this.form.aim_city[2]];
				
				if(!(case_prov&&case_city&&case_county)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少装货地信息'
					});
					return;
				}else{
					post_data.set.case_prov=case_prov
					post_data.set.case_city=case_city
					post_data.set.case_county=case_county
				}

				if(!(aim_prov&&aim_city&&aim_county)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少卸货地信息'
					});
					return;
				}else{
					post_data.set.aim_prov=aim_prov
					post_data.set.aim_city=aim_city
					post_data.set.aim_county=aim_county
				}

				if(this.form.case_addr.trim()){//发货地详细地址
					post_data.set.case_addr=this.form.case_addr.trim()
				}

				if(this.form.aim_addr.trim()){//卸货地详情
					post_data.set.aim_addr=this.form.aim_addr.trim()
				}

				if(this.form.case_date){//装货日期
					let case_date_start=this.form.case_date[0].getTime();
					let case_date_end=this.form.case_date[1].getTime();
					
					post_data.set.case_date_start=(case_date_start/1000).toFixed(0)
					post_data.set.case_date_end=(case_date_end/1000).toFixed(0)
				}

				if(this.form.aim_date){//卸货日期
					let aim_date_start=(this.form.aim_date[0].getTime()/1000).toFixed(0);
					let aim_date_end=(this.form.aim_date[1].getTime()/1000).toFixed(0);

					if(post_data.set.case_date_end&&aim_date_start<post_data.set.case_date_end){
						this.$my.other.msg({
							type:"warning",
							str:'卸货日期起点不能早于装货日期止点'
						});
						return;
					}
					post_data.set.aim_date_start=aim_date_start
					post_data.set.aim_date_end=aim_date_end
				}

				if(this.form.need_truck_type){//租用货车类型
					post_data.set.need_truck_type=this.form.need_truck_type[0]
					post_data.set.need_truck_type2=this.form.need_truck_type[1]
				}

				if(this.status==2){

					//
					if(!(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0)){//运费
						this.$my.other.msg({
							type:"warning",
							str:'请输入正确的运费信息'
						});
						return;
					}

					post_data.set.freight_total=this.form.freight_total
					
				}else if(this.status==1){

					if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){//运费
						post_data.set.freight_total=this.form.freight_total
					}
				}

				post_data.set.settlement_type=this.form.settlement_type//结算方式(1:按吨数,2:按方数,3:按趟)
				post_data.set.is_need_return=this.form.is_need_return//是否回程(1:需要,2:不需要)
				post_data.set.distance=this.form.distance//运输距离(单位:公里)
				post_data.set.out_trade_num=this.form.out_trade_num.trim()//上游运单编号
				post_data.set.group1_num=this.form.group1_num.trim()//组编号1(用户自定义打组)
				post_data.set.mark=this.form.mark.trim()//备注

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{
						
						//添加成功
						this.$my.other.msg({
							type:'success',
							str:"修改成功",
						});

						//跳转
						this.$router.push({
							path:this.come_from
						});
					}
				});
			},

			//货物名称输入
			cargo_name_change(){
				
				//查看此输入内容是否已收录
				let hasinputed=false;
				let used_cargo_names=[...this.used_cargo_names];
				for(var i in used_cargo_names){
					
					let item=used_cargo_names[i]

					//已收录,挪到第一位
					if(this.form.cargo_name==item){
						
						//删除原数组中对应的元素
						this.used_cargo_names.splice(i,1);

						//在原数组中新增此元素,并放到第一个未知
						this.used_cargo_names.unshift(this.form.cargo_name);
						
						//已收录
						hasinputed=true;
						break;
					}
				}

				//如果没有收录
				if(!hasinputed){

					//收入之
					this.used_cargo_names.unshift(this.form.cargo_name);
				}

				//保存到本地硬盘
				window.localStorage.setItem('cargo_creat_page_used_cargo_names',JSON.stringify(this.used_cargo_names))
			},

			//使用过的货物名称初始化
			set_used_cargo_name(){
				let used_cargo_name=window.localStorage.getItem('cargo_creat_page_used_cargo_names');
				if(used_cargo_name){
					used_cargo_name=JSON.parse(used_cargo_name);
				}else used_cargo_name=[];
				this.used_cargo_names=[...used_cargo_name];
			},

			//获取货源详情
			get_cargo_info(){

				//取出货源id
				let cargo_id=this.$route.query.cargo_id;
				if(this.$my.check.is_num(cargo_id)){
					this.cargo_id=cargo_id;
					
				}else{
					this.$my.other.msg({
						type:'warning',
						str:'参数有误,请退回货源列表页重新进入'
					});
					return;
				}

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');
				let is_get_creat_para=1;

				//如果取出成功
				if(creat_para){
					is_get_creat_para=2
					this.cargo_type=[...creat_para.cargo_type]
					this.truck_type=[...creat_para.truck_type]
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_info_pub',
						is_get_creat_para:is_get_creat_para,
						cargo_id:this.cargo_id,
					},
					callback:(data)=>{

						//缓存创建参数
						if(is_get_creat_para==1){

							//缓存创建参数
							let truck_type=truck_type_handle.for_el_cascader(data.creat_para.truck_type)
							this.$my.cache.set({
								cargo_creat_page_creat_para:{
									cargo_type:data.creat_para.cargo_type,
									truck_type:truck_type,
								}
							});

							//把创建参数置入页内
							this.cargo_type=[...data.creat_para.cargo_type]
							this.truck_type=[...truck_type]
						}
						
						//处理货源数据,使之与页内数据匹配
						let cargo_info={...data.cargo_info}

						//货源状态
						this.status=cargo_info.status;

						//装货地
						cargo_info.case_city=[
							TextToCode[cargo_info.case_prov].code,
							TextToCode[cargo_info.case_prov][cargo_info.case_city].code,
							TextToCode[cargo_info.case_prov][cargo_info.case_city][cargo_info.case_county].code,
						];
						cargo_info.case_addr=cargo_info.case_addr
						delete(cargo_info.case_prov);
						delete(cargo_info.case_county);
						
						//卸货地省市区
						cargo_info.aim_city=[
							TextToCode[cargo_info.aim_prov].code,
							TextToCode[cargo_info.aim_prov][cargo_info.aim_city].code,
							TextToCode[cargo_info.aim_prov][cargo_info.aim_city][cargo_info.aim_county].code,
						];
						cargo_info.aim_addr=cargo_info.aim_addr
						delete(cargo_info.aim_prov);
						delete(cargo_info.aim_county);
						
						//装货时间
						if(cargo_info.case_date_start>0&&cargo_info.case_date_end>0){
							cargo_info.case_date=[
								new Date(cargo_info.case_date_start*1000),
								new Date(cargo_info.case_date_end*1000)
							];
							delete(cargo_info.case_date_end);
							delete(cargo_info.case_date_start);
						}else cargo_info.case_date=null;

						//卸货时间
						if(cargo_info.aim_date_start>0&&cargo_info.aim_date_end>0){
							cargo_info.aim_date=[
								new Date(cargo_info.aim_date_start*1000),
								new Date(cargo_info.aim_date_end*1000)
							];
							delete(cargo_info.aim_date_start);
							delete(cargo_info.aim_date_end);
						}else cargo_info.aim_date=null;

						//车型
						cargo_info.need_truck_type=[
							cargo_info.need_truck_type,
							cargo_info.need_truck_type2,
						];
						delete(cargo_info.need_truck_type2);
						
						//运费处理
						cargo_info.freight=cargo_info.freight==0?'':cargo_info.freight
						
						//置入数据
						this.form=cargo_info
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 20px 20px 0 20px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.page{
		background-color: #fff;
		border-radius: 10px;
		padding:0 15px 15px 15px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>